<template>
  <div>
    <new-document
      :type="this.$route.params.type"
      :action="this.$route.params.action"
      :id="this.$route.params.id"
    />
  </div>
</template>
<script>
import NewDocument from '@/components/NewDocument/Index'

export default {
  components: {
    NewDocument,
  },
  data() {
    return {}
  },
  watch: {},
}
</script>
